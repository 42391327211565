header {
  background-color: #ffffff;
  color: #333;
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-bottom: 2px solid #ff0000;
}

header.scrolled {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo h1 {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  color: #ff0000;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin-bottom: 5px;
  transition: transform 0.3s ease;
}

.hamburger.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-left: 30px;
}

.language-dropdown {
  position: relative;
}

.language-dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  transition: color 0.3s ease;
}

.language-dropdown-toggle:hover {
  color: #ff0000;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 120px;
  z-index: 1;
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 12px;
  text-align: left;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
}

.dropdown-menu button:hover {
  background-color: #f5f5f5;
  color: #ff0000;
}


@media screen and (max-width: 768px) {
  header {
    padding: 10px 0;
  }

  .logo h1 {
    font-size: 24px;
    margin-bottom: 5px; /* 変更: 下部のマージンを減らす */
  }

  .hamburger {
    display: block;
    margin-left: auto;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    border-bottom: 2px solid #ff0000;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .nav-links.active {
    display: block;
  }

  .language-dropdown {
    margin-top: 5px; /* 変更: 上部のマージンを減らす */
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    min-width: unset;
    margin-top: 5px;
  }

  .dropdown-menu button {
    text-align: center;
  }
}
