.how-to-use-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffe6e6; /* 薄い赤の背景色 */
  border-radius: 10px; /* 角を丸くする */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 影をつける */
}

.how-to-use-container h2 {
  font-size: 28px; /* フォントサイズを少し大きくする */
  margin-bottom: 20px;
  color: #333; /* 濃い灰色の見出し色 */
}

.how-to-use-container ol {
  font-size: 18px;
  line-height: 1.6;
  padding-left: 20px; /* リストの左側に余白をつける */
}

.how-to-use-container li {
  margin-bottom: 15px;
}

.how-to-use-container li::marker {
  color: #AF4C4C; /* リストマーカーの色を変更 */
  font-weight: bold; /* リストマーカーを太字にする */
}

.how-to-use-container strong {
  color: #AF4C4C; /* 重要な部分の色を変更 */
}

.how-to-use-container p {
  margin-top: 10px; /* 段落の上部に余白をつける */
  color: #666; /* 段落の色を少し薄くする */
  font-style: italic; /* 段落をイタリック体にする */
}
