/* Footer.css */
.footer {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    margin-top: 20px;
    font-size: 16px;
    color: #333;
    border-top: 1px solid #e7e7e7;
}

