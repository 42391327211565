/* Splash.css */
#splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.splash-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.encrypted-text {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 4px;
}

.decrypted-text {
  color: #fff;
}

#splash-screen img {
  width: 200px;
  height: auto;
  margin-top: 20px;
}
