#contact-us {
  padding: 20px;
  text-align: center;
  background-image: url('../assets/IMG_9965_darkest.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* 文字色を白に設定 */
}

#contact-us h2 {
  font-size: 2.5rem; /* 見出しのフォントサイズを大きくする */
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 見出しに影をつける */
}

.contact-info {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.contact-info p {
  margin: 10px 0;
  color: #333; /* コンタクト情報の文字色を黒に設定 */
}

.contact-info a {
  color: #007BFF;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
