#hero {
  position: relative;
  width: 100%;
  height: 100vh; /* 画面の高さいっぱいに表示 */
  overflow: hidden;
  padding-top: 60px;
}
.hero-slideshow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}
.hero-slide:first-child {
  display: block;
}
.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 10;
  width: 90%; /* 画面幅の90%まで使用 */
  max-width: 800px; /* 最大幅を設定 */
  margin: 0 auto; /* 中央寄せ */
}
.hero-text h2 {
  font-size: 4em;
  margin: 0;
  padding: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.hero-text p {
  font-size: 1.25em;
  margin-top: 0.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .hero-text h2 {
    font-size: 3em; /* タブレット画面でのフォントサイズ */
  }
}

@media screen and (max-width: 480px) {
  .hero-text h2 {
    font-size: 2em; /* スマートフォン画面でのフォントサイズ */
  }
}
