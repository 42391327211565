.service-plans-container {
  background-color: ivory;
  padding: 20px;
}

.plans-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.plan {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  width: 45%;
}

.plan:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.plan.selected {
  background-color: #007BFF;
  color: white;
}

.plan h3 {
  margin-bottom: 10px;
}

.payment-container {
  margin-top: 20px;
}
