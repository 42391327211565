/* ConceptSection.css */

#concept {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f8f8f8;
}

#concept h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  color: #1a1a1a;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.section-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
}

.section-item::before {
  content: '';
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #1a1a1a;
  text-align: center;
}

.section-item p {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.section-item ul {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.section-item li {
  font-size: 16px;
  padding: 15px;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.section-item li:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.section-item li strong {
  font-weight: bold;
  color: #1a1a1a;
}

.section-item p:last-of-type {
  font-size: 20px;
  font-weight: bold;
  color: #ff6b6b;
  text-align: center;
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  #concept h2 {
    font-size: 48px;
  }

  .section-title {
    font-size: 36px;
  }

  .section-item p {
    font-size: 20px;
  }

  .section-item ul {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .section-item li {
    font-size: 18px;
  }

  .section-item p:last-of-type {
    font-size: 24px;
  }
}
