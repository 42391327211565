.business-info-header {
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}

.toggle-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  transition: transform 0.3s ease-in-out;
}

.toggle-icon.open {
  transform: translateY(-50%) rotate(135deg);
}
