#concept {
    margin-bottom: 20px; /* 各セクションの下部にスペースを追加 */
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.section-title {
    font-size: 24px;
    font-weight: bold;
    color: #007BFF;
    margin-top: 20px;
}

.collapsed {
    display: none;
}

.expanded {
    display: block;
}

button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

