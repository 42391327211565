/* Form.css */
.form-container {
 max-width: 400px;
 margin: 0 auto;
 padding: 40px;
 background-color: #ffffff;
 border-radius: 10px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 text-align: center;
 position: relative;
 overflow: hidden;
}

.form-container::before {
 content: "";
 position: absolute;
 top: -20px;
 left: -20px;
 right: -20px;
 bottom: -20px;
 background-image: radial-gradient(circle, #b8d8be, #b8d8be 2px, transparent 2px, transparent 100%),
   radial-gradient(circle, #b8d8be, #b8d8be 1px, transparent 1px, transparent 100%);
 background-size: 40px 40px, 20px 20px;
 background-position: 0 0, 10px 10px;
 opacity: 0.2;
 z-index: -1;
}

.form-container h2 {
 font-size: 28px;
 margin-bottom: 20px;
 color: #333;
}

.form-container form {
 display: flex;
 flex-direction: column;
}

.form-container label {
 text-align: left;
 margin-bottom: 5px;
 font-size: 16px;
 color: #333;
}

.form-container input {
 width: 100%;
 padding: 12px;
 margin-bottom: 15px;
 border: 1px solid #ccc;
 border-radius: 5px;
 font-size: 16px;
 background-color: #f7f7f7;
}

.form-container button {
 width: 100%;
 padding: 12px;
 background-color: #4CAF50;
 color: #ffffff;
 border: none;
 border-radius: 5px;
 font-size: 16px;
 cursor: pointer;
 transition: background-color 0.3s ease;
 margin-top: 20px;
}

.form-container button:hover {
 background-color: #45a049;
}

.form-container .google-button {
 display: inline-flex;
 align-items: center;
 justify-content: center;
 background-color: #ffffff;
 color: #757575;
 border: 1px solid #dadce0;
 border-radius: 4px;
 padding: 12px 24px;
 font-size: 16px;
 font-weight: 500;
 cursor: pointer;
 transition: background-color 0.3s ease;
 margin-top: 20px;
}

.form-container .google-button:hover {
 background-color: #f5f5f5;
}

.form-container .google-button img {
 width: 18px;
 height: 18px;
 margin-right: 12px;
}

.form-container .message {
  display: none;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.form-container .message.visible {
  display: block;
}

.form-container .message.success {
 background-color: #d4edda;
 color: #155724;
 border: 1px solid #c3e6cb;
}

.form-container .message.error {
 background-color: #f8d7da;
 color: #721c24;
 border: 1px solid #f5c6cb;
}
